// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-consultation-and-facilitation-js": () => import("./../../../src/pages/consultationAndFacilitation.js" /* webpackChunkName: "component---src-pages-consultation-and-facilitation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-clearing-js": () => import("./../../../src/pages/customClearing.js" /* webpackChunkName: "component---src-pages-custom-clearing-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-importing-equipments-js": () => import("./../../../src/pages/importingEquipments.js" /* webpackChunkName: "component---src-pages-importing-equipments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-port-clearing-js": () => import("./../../../src/pages/portClearing.js" /* webpackChunkName: "component---src-pages-port-clearing-js" */),
  "component---src-pages-transportation-js": () => import("./../../../src/pages/transportation.js" /* webpackChunkName: "component---src-pages-transportation-js" */)
}

